/* You can add global styles to this file, and also import other style files */
@import 'variables';

// ng2-dragula styles
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}
// end ng2-dragula styles

body {
  height: 100%;

  a {
    color: $textcolor_blue;
  }
}

.card-content-wrapper {
  &.card-service {
    top: 419px;

    &.msd-open {
      top: 256px;
    }
  }

  &.card-failure {
    top: 369px;
  }
}

.vwblue {
  color: $textcolor_blue;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.icon-upload {
  background-image: url(../../assets/images/upload.svg);
  background-position: 11px 13px;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
}

.icon-blue-plus {
  background-image: url(../../assets/images/blue_plus.svg);
  background-position: 0 0;
  background-repeat: no-repeat;

  width: 20px;
  height: 20px;
}

.tag--large {
  width: 120px;
  text-align: center;
  height: 35px;
  padding-top: 5px;
}

.text-bold {
  font-weight: bold;
}

.failures-wrapper {
  margin-left: 30px;
  margin-right: 30px;

  .failures-cases {
    .failure-case {
      margin-top: 40px;
      margin-bottom: 40px;

      .failure-heading {
        span {
          color: #34393b;
          font-weight: bold;
          font-size: 20px;
        }

        hr {
          margin-top: 15px;
        }
      }

      .failure-body {
        background-color: #edf0f2;

        .failure-use-case {
          .row {
            float: left;
            margin-left: 0px;
            padding-left: 30px;
          }

          .inner-box {
            margin-top: 30px;
            margin-bottom: 30px;
            margin-right: 30px;
            height: 175px;
            width: 160px;

            // create box
            &.first {
              border: 1px dotted #80b0c8;
              border-radius: 3px;
              background-color: #f6f6f6;
              height: 190px;
            }
          }

          .use-case-create {
            padding: 80px 70px;
            text-align: center;
          }

          .card-statecodes {
            .card__content {
              padding: 0;

              .statecode-name {
                padding: 30px 25px;
                background: radial-gradient(circle, #ffffff 0%, #e7ebef 100%);
                text-align: center;

                &.hover,
                &.hover-0,
                &.hover-1,
                &.hover-2,
                &.hover-3,
                &.hover-4,
                &.hover-5,
                &.hover-6,
                &.hover-7,
                &.hover-8 {
                  background: #004666;
                  position: relative;
                }

                .hover-checkbox {
                  position: absolute;
                  background: none;
                  left: 15px;
                  top: 10px;

                  .icon {
                    position: relative;
                    color: #fff;
                    left: 55px;
                    top: -8px;
                  }
                }

                .statecode-name-value {
                  font-weight: bold;
                  color: #4c5356;
                }

                .statecode-use-case {
                  font-weight: bold;
                  font-size: 12px;
                  color: #787b80;
                }
              }

              .statecode-measurement {
                padding: 15px 25px;
                background-color: #fff;

                .measurement-title {
                  color: #bdc1c7;
                }
                .measurement-value {
                  font-weight: bold;
                  color: #037da6;
                }
              }
            }
          }
        }
      }

      .failure-seperator {
        clear: both;
        padding-top: 20px;

        hr {
          background-color: #edf0f2;
          opacity: 0.4;
        }
      }
    }

    .add-case-content {
      margin-top: 30px;
      color: #006384;

      .add-case {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        padding-left: 15px;
      }
    }
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
