:root {
  color-scheme: light;
  --color-0: #00000000;
  --color-1: #ffff00ff;
  --color-6: #ffffffff;
  --color-52: #bdc1c740;
  --color-9: #037da6ff;
  --color-5: #787b80ff;
  --color-54: #0079a8ff;
  --color-66: #f6f7f7ff;
  --color-3: #edf0f2ff;
  --color-31: #eb6d20ff;
  --color-68: #646d71ff;
  --color-35: #00000014;
  --color-47: #006999ff;
  --color-29: #d6e6edff;
  --color-34: #0000001a;
  --color-75: #1a1a1a00;
  --color-7: #006384ff;
  --color-23: #dee0e3ff;
  --color-50: #0000001f;
  --color-10: #dadde0ff;
  --color-24: #edf0f252;
  --color-76: #edf0f252;
  --color-19: #004666e5;
  --color-70: #d9dbdeff;
  --color-48: #00000026;
  --color-72: #fdd854ff;
  --color-49: #00000029;
  --color-22: #005475ff;
  --color-62: #b8d4e0ff;
  --color-43: #4c5356ff;
  --color-56: #005075ff;
  --color-15: #fccd22ff;
  --color-30: #aeccdcff;
  --color-59: #ffcb0cff;
  --color-64: #d44979ff;
  --color-60: #e9c866ff;
  --color-65: #c3c7cbff;
  --color-8: #004666ff;
  --color-61: #a4c6d7ff;
  --color-58: #b7c585ff;
  --color-51: #0000003d;
  --color-16: #bdc1c7ff;
  --color-57: #adbf60ff;
  --color-73: #7ebf60ff;
  --color-41: #94bed1ff;
  --color-4: #34393bff;
  --color-40: #e3b839ff;
  --color-45: #00000047;
  --color-38: #787b8033;
  --color-39: #a3b562ff;
  --color-44: #0000004d;
  --color-13: #80b0c8ff;
  --color-12: #a8adb3ff;
  --color-20: #002d42ff;
  --color-42: #bd2d5fff;
  --color-17: #edf0f2a3;
  --color-77: #edf0f2a3;
  --color-27: #d8aa00ff;
  --color-11: #64a844ff;
  --color-26: #95a844ff;
  --color-63: #cd2661ff;
  --color-71: #f32639ff;
  --color-53: #04a3d8ff;
  --color-36: #787b8024;
  --color-25: #a21e4dff;
  --box-shadow-1: #787b801a;
  --color-18: #80b0c852;
  --color-37: #787b801f;
  --color-69: #1a1a1aff;
  --color-28: #037da614;
  --color-67: #929599ff;
  --box-shadow-2: #787b8014;
  --color-14: #da0c1fff;
  --color-46: #00000073;
  --color-74: #ef8c4fff;
  --color-55: #0089b7ff;
  --color-2: #000000ff;
  --color-21: #ffffff00;
  --background-color: #ffffff;
  --text-color-bar: #ffffff;
  --input-color: #004666ff;
  --checkbox-switch: #ffffffff;
  --notification-color: #34393bff;
  --color-78: #dddddd;
  --black-white: #000;

  /* Diagnosis Table */
  --table-header: #dadde0;
  --top-border: #8c8b8b;
  --error-1: rgb(255, 220, 220);
  --error-2: rgb(255, 230, 230);
  --error-3: rgb(255, 196, 196);
  --success-1: rgb(220, 255, 220);
  --success-2: rgb(230, 255, 230);
  --success-3: rgb(196, 255, 196);

}

[data-theme="dark"] {
  color-scheme: dark;
  --color-3: #34393B;
  --color-6: #282C2D;
  --color-4: #ffffff;
  --background-color: #282C2D;
  --box-shadow-1: rgba(0, 0, 0, 0.20);
  --box-shadow-2: rgba(0, 0, 0, 0.10);
  --color-20: #1B1E1F;
  --color-8: #282C2D;
  --text-color-bar: #ffffff;
  --input-color: #349CA0;
  --color-7: #37B4BA;
  --color-9: #3CC0C9;
  --color-22: #2a3e40;
  --color-13: #2e6164;
  --checkbox-switch: #34393B;
  --color-44: rgba(0, 0, 0, 0.3);
  --color-45: rgba(0, 0, 0, 0.5);
  --color-10: #4C5356;
  --color-47: #349CA0;
  --color-78: #34393B;
  --black-white: #fff;
  --color-36: rgba(0, 0, 0, 0.1);
  --color-37: rgba(0, 0, 0, 0.23);
  --color-38: rgba(0, 0, 0, 0.17);
  --color-29: rgb(51, 56, 58);
  --color-30: rgb(69, 76, 78);
  --color-18: #41E0E652;
  --color-76: #34393B52;
  --color-77: #34393Ba3;
  --color-16: #454c4e;
  --color-19: #349CA0e5;

  /* Diagnosis Table */
  --error-1: rgb(80, 50, 50);
  --error-2: rgb(66, 36, 36);
  --error-3: rgb(121, 0, 0);
  --success-1: rgb(50, 80, 50);
  --success-2: rgb(36, 66, 36);
  --success-3: rgb(0, 121, 0);
}


html[data-theme="dark"] .owl-dt-container {
  background: var(--color-6);
}

html[data-theme="dark"] .owl-dt-calendar-cell-selected {
  background-color: var(--color-7);
}

html[data-theme="dark"] .owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: inset 0 0 0 1px var(--color-6);
}

html[data-theme="dark"] .owl-dt-calendar-control,
html[data-theme="dark"] .owl-dt-control-button-arrow {
  color: var(--color-4);
  fill: var(--color-4);
}

html[data-theme="dark"] .owl-dt-calendar-table,
html[data-theme="dark"] .owl-dt-calendar-header,
html[data-theme="dark"] .owl-dt-calendar-cell-content {
  color: var(--color-4);
}

html[data-theme="dark"] .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: #ffffff3c;
}

html[data-theme="dark"] .owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
html[data-theme="dark"] .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: var(--color-9);
}

html[data-theme="dark"] .owl-dt-calendar-cell-today {
  border-color: var(--color-9);
}

html[data-theme="dark"] .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: #ffffff3c;
}

html[data-theme="dark"] .owl-dt-timer-input {
  background: var(--color-6);
  border-color: white;
  color: var(--color-4);
}

html[data-theme="dark"] .owl-dt-popup-container {
  box-shadow: 0 5px 5px -3px var(--box-shadow-1), 0 8px 10px 1px var(--box-shadow-2), 0 3px 14px 2px var(--box-shadow-1);
}

html[data-theme="dark"] .owl-dt-control-arrow-button[disabled] {
  color: #ffffff3c;
}

html[data-theme="dark"] .owl-dt-container-buttons {
  color: var(--color-7);
}

html[data-theme="dark"] .owl-dt-container-row:not(:last-child) {
  border-bottom: 1px solid #ffffff3c;
}

html[data-theme="dark"] .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
  background: #ffffff3c;
}

html[data-theme="dark"] i.icon.i-close-circle.left {
  color: var(--color-4) !important;
}

html[data-theme="dark"] div.preloader--golf {
  filter: brightness(0) invert(1);
  background-color: transparent;
}

html[data-theme="dark"] app-newsletter .card {
  box-shadow: none;
}

html[data-theme="dark"] app-newsletter .text-white {
  color: #000 !important;
}

html[data-theme="dark"] div.markdown-body {
  color: white;
}

html[data-theme="dark"] .icon-blue-plus{
  filter: hue-rotate(-17deg) saturate(51%) brightness(210%);
}

html[data-theme="dark"] .modal-head-wrapper {
  background-color: transparent;
}

html[data-theme="dark"] .icon-circle-menu{
  filter: brightness(0) invert(1);
  background-color: transparent;
}
html[data-theme="dark"] .modal__close:hover{
  color: #2f979d;
}

html[data-theme="dark"] .i-clock{
  filter: brightness(0) invert(1);
}
